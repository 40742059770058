<template>
  <div class="">
    <!-- Button pour archiver un compte -->
    <b-button
      v-b-tooltip.hover
      title="Supprimer ce compte"
      variant="danger"
      @click="showConfirmeArchiveAgentModal"
    >
      <feather-icon icon="ArchiveIcon" />
      Supprimer
      <span class="text-nowrap" />
    </b-button>

    <!-- Modal pour notifier l'archivage du compte -->
    <b-modal
      id="modal-1"
      :ref="confirmeArchiveAgentModalRef"
      title="Alerte d'archivage"
      ok-only
      ok-title=""
      ok-variant=""
    >
      <b-card-text>
        Êtes-vous sûre de vouloir supprimer le compte de cet utilisateur ?
      </b-card-text>
      <template #modal-footer>
        <div class="">
          <b-button variant="danger" @click="archiverAgent">
            <b-spinner v-if="isarchivingAgent" small /> Supprimer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BSpinner,
  VBTooltip,
  VBModal,
  BModal,
  BCardText,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// store module and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  name: "ListAgentsComponent",
  components: {
    BButton,
    BSpinner,
    BModal,
    BCardText,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
  },
  props: {
    agentEtablissementId: {
      type: String,
      default: "",
    },
    inputDatas: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      confirmeArchiveAgentModalRef: "confirmeArchiveAgentModal",

      isLoadingTableData: false,
      isarchivingAgent: false,
    };
  },

  async mounted() {},
  methods: {
    ...mapActions("etablissements", {
      getAgent: "getAgent",
      action_archiverAgent: "archiverAgent",
    }),
    archiverAgent() {
      this.isarchivingAgent = true;
      const data = { id: this.agentEtablissementId };
      console.log("agentId", data);
      console.log("oook");
      this.action_archiverAgent(data)
        .then((response) => {
          this.isarchivingAgent = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Opération réussie",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$router.push({ name: "etablissements-agents-list" });

          this.hideConfirmeArchiveAgentModal();
          console.log(response.data);
        })
        .catch((error) => {
          this.isarchivingAgent = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          // console.log(error)
        });
    },
    showConfirmeArchiveAgentModal() {
      this.$refs[this.confirmeArchiveAgentModalRef].show();
    },
    hideConfirmeArchiveAgentModal() {
      this.$refs[this.confirmeArchiveAgentModalRef].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
